const add ="/push/add";
const pushServer ="/push/pushServer";
const page ="/push/page";
const rePush='/push/rePush';
const rollBack='/push/rollBack';
const deleteInfo='/push/deleteInfo';
const deleteInfos='/push/deleteInfos';
const getJarLog='/push/getJarLog';
const getBarJarLog='/push/getBarJarLog';

export default {
    add,pushServer,page,rePush,rollBack,deleteInfo,deleteInfos,getJarLog,getBarJarLog
  }