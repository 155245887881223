<template>
  <div class="container">

    <el-container style="border: 1px solid #eee;height: 100%;width: 100%;">
      <el-aside width="50%" style="border-right: 1px solid snow;">
        <el-tabs style="margin:10px 0px 0px 10px" type="card" @tab-click="tabClick" v-model="activeName">
          <el-tab-pane v-for="(row, index)  in accountsGroup" :key="index" :name="index">
            <span slot="label"> {{ index }}</span>
            <div class="main-upload">
              <div class="main-radio">
                <div style="margin-top:30px;display: flex;justify-content: space-between;">
                  <span>
                    环境： <el-radio-group v-for="item in buttionArr" :key="item.id" v-model="env" @change="handleEnvClick"
                      size="mini">
                      <el-radio-button :label="item.menuName" class="radio_style"></el-radio-button>
                    </el-radio-group>
                  </span>
                  <span style="margin-right:120px">
                    <el-tooltip class="item" effect="dark" :content="ip" placement="top">
                      <a :href="baoTaPath" :hidden="false" target="_blank" style="margin-left:15px">
                        <el-button type="warning" size="mini" icon="el-icon-share" round> 前往宝塔 </el-button>
                      </a>
                    </el-tooltip>
                    <el-tooltip class="item" effect="dark" style="margin-left: 15px;" :content="pageUrlTaPath" placement="top">
                      <transition name="el-zoom-in-center">
                        <a :href="pageUrlTaPath"  :hidden="pageUrlTaPath=='#'" target="_blank">
                          <el-button  type="primary" size="mini" icon="el-icon-loading" round> 前往网站
                          </el-button>
                        </a>
                      </transition>
                    </el-tooltip>
                  </span>
                </div>
                <div>

                </div>
               
                <div style="margin-top:30px;display: flex;">

                  <div style="width: 48px"> 端口：</div>
                  <div style="width: 95%">
                    <el-radio-group v-for="item in powerList" :key="item.id"
                      v-model="powerType" @change="handlePowerClick" size="mini" >
                     
                      <el-radio-button :label="item.name"   class="radio_style"> </el-radio-button>
   
                    </el-radio-group>
                  </div>

                </div>

                <div style="margin-top:30px;display: flex;justify-content: space-between;">
                  <span>
                    地址：<el-radio-group v-for="item in acounts" :key="item.id" v-model="ip" @change="handleAccountClick"
                      size="mini" style="margin-left:5px" :fill='fillColor'>

                      <el-radio-button :hidden="hiddenPath" :label="item.group.substring(0, 1)" disabled
                        :class="{ 'ip_rb_first': item.group != 'mhp' }"
                        :style="{ 'background-color': selectedColor }"></el-radio-button>
                      <el-radio-button :hidden="hiddenPath" :label="item.ip"></el-radio-button>
                    </el-radio-group>
                  </span>
                  <span style="margin-right:100px" v-if="powerType.indexOf('Java') ==-1 && powerType != 'Android'">
                    <el-tooltip class="item" effect="dark" content="是=删除所有文件后创建；否=覆盖" placement="top">
                      <el-button type="text">清理文件目录：</el-button>
                    </el-tooltip>

                    <el-radio v-model="removeFileRadio" :label="true">是</el-radio>
                    <el-radio v-model="removeFileRadio" :label="false">否</el-radio>
                  </span>
                </div>

              </div>
            </div>
          </el-tab-pane>
        </el-tabs>
        <div v-if="powerType == 'Android'" style="margin:30px 0px 0px 55px">
          <div style="">
            <span>
              App：
            </span>
            <span style="margin-left:10px">
              <el-tooltip class="item" effect="dark" content="是=强制用户更新app；否=不强制" placement="top">
                <el-button type="text" style="color:black">

                  <el-tag effect="dark" type='success' size="mini">
                    是否强制更新：
                  </el-tag>
                </el-button>
              </el-tooltip>
              <span style="margin-left:10px">
                <el-radio v-model="forceUpdateRadio" :label="true">是</el-radio>
                <el-radio v-model="forceUpdateRadio" :label="false">否</el-radio>
              </span>

            </span>
            <span style="margin-left:50px">
              <el-tooltip class="item" effect="dark" content="即时更新=立即更新，app弹出更新提醒；否=不更新" placement="top">
                <el-button type="text" style="color:black">
                  <el-tag effect="dark" type='success' size="mini">
                    发布方式：
                  </el-tag>
                </el-button>
              </el-tooltip>
              <span style="margin-left:10px">
                <el-radio v-model="canUpdateRadio" :label="true">即时更新</el-radio>
                <el-radio v-model="canUpdateRadio" :label="false">暂不更新</el-radio>
              </span>
            </span>

          </div>
          <div >
            <div style="margin-left: 55px;padding:10px 0px 10px 0px">
              <el-tag effect="dark" type='success' size="mini">
                更新说明：
              </el-tag>
              <quill-editor class="editor" ref="myTextEditor" style="margin-top: 10px;" v-model="content">
            </quill-editor>
            </div>

          
          </div>
        </div>

        <div v-if="this.powerType.indexOf('特斯科-Android') !=-1"  style="margin:30px 0px 0px 55px;">
           
            <el-tag>
              App：
            </el-tag>
           

           <div style="display: flex; margin: 30px 0px 0px 10px;">
            <div style="width: 100px;margin-top: 3px;"> 服务器地址：</div>
            <div style="width: 95%">
              <el-input size="mini" v-model="serverUrl" :disabled="true" style="width: 200px"></el-input>
            </div>
           </div>
           <div style=" margin: 30px 120px 0px 10px; float: right;">
             <el-button type="primary" :hidden="appFileList.length == 0" size="mini" @click="pushServerClick">APP 发布</el-button>
           </div>
           
        </div>

        <div  >
        <div style="margin:30px 0px 0px 55px"> 文件：</div>
        <div style="display:flex;">
          <div style="margin:-20px 0px 0px 105px;width:400px">
            <el-upload ref="uploadFile" class="upload-demo" drag action="String" :auto-upload="false" :limit="limit"
              :show-file-list="showFilelList" :multiple="true" :accept="accept"
              :disabled="content == '' && powerType == 'Android'" :on-change="changeFile" :file-list="fileList">
              <i class="el-icon-upload"></i>
              <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>

              <div v-if="powerType.indexOf('特斯科-Android') ==-1" :hidden="fileList.length == 0" class="el-upload__tip" @click="submit"
                style="cursor:pointer ;background-color: green;margin-left: 260px;line-height: 35px;width: 100px;text-align: center;color: white;border-radius: 6px;"
                slot="tip">上传并发布</div>
              <div class="el-upload__tip"
                style="font-size: 13px;font-weight: 800;display: flex; justify-content: space-between;" slot="tip">
                <span>
                  只能上传
                  <span style="color:blue"> {{ accept }} </span>
                  格式的压缩文件
                 
                </span>
              </div>
            </el-upload>

          </div>
          <div v-if="powerType.indexOf('Android') !=-1">
            <span v-if="appFileList.length > 0">
              <el-badge :value="appFileList.length" class="item">
                <i class="el-icon-files" style="font-size: 25px; font-weight: 900;color:goldenrod"
                  @click="appFielsView"></i>
              </el-badge>
              <el-badge :value="appFileList.length" class="item">
                <i class="  el-icon-delete-solid"
                  style="font-size: 25px; font-weight: 900;color:red;margin:0px 0px 0px 50px;" @click="clearFiles"></i>
              </el-badge>
            </span>
          </div>
        </div>
       </div>
      </el-aside>
      <el-container>
        <el-main>
          <div class="main-table">
            <div class="main-table-top">
              <div style="width: 350px;margin: 15px ;display: flex;">
                <div style="margin-right:20px">
                  <el-input placeholder="请输入内容" v-model="pushInfoSearch" class="input-with-select" @change="pushSearch">
                    <el-button slot="append" icon="el-icon-search" @click="pushSearch"></el-button>
                  </el-input>
                </div>

                <el-button type="danger" @click="deleteBatchsInfo">批量删除</el-button>
              </div>
            </div>
            <div style="margin-top:5px">

              <el-table :data="tableData" stripe size="small" width="100" :default-expand-all="false"
                @row-dblclick="tableDbClickHandeler" @selection-change="handleSelectionChange">
                <el-table-column type="selection" width="50">

                </el-table-column>
                <el-table-column align="center" width="50" prop="env" label="环境">
                </el-table-column>
                <el-table-column align="center" width="120" prop="serverPort" label="端口">
                </el-table-column>
                <el-table-column align="center" width="200" prop="fileName" label="文件名">
                </el-table-column>
                <el-table-column align="center" width="150" prop="createDate" label="发布日期">
                </el-table-column>
                <el-table-column align="center" width="100" prop="childList[0].userName" label="发布人">
                </el-table-column>
                <el-table-column label="操作" align="center">
                  <template slot-scope="props">
                    <el-button v-if="powerType.indexOf('Android') ==-1" type="info" size="mini" @click="view(props.row)">查看
                    </el-button>
                    <el-button v-if="powerType.indexOf('Android') ==-1" type="primary" size="mini" @click="acounts.length>1? otherPushDialog(props.row): push(props.row)">发布
                    </el-button>
                    <el-button type="danger" size="mini" @click="deleteInfo(props.row)">删除</el-button>
                  </template>
                </el-table-column>
              </el-table>
            </div>

          </div>
          <div class="main-page">
            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
              :current-page="currentPage" :page-sizes="pageSizeArr" :page-size="pageSize"
              layout="total, sizes, prev, pager, next, jumper" :total="total">
            </el-pagination>
          </div>
        </el-main>

      </el-container>


    </el-container>
    <el-drawer title="我是标题" direction="rtl" size="300px" :visible.sync="drawer" :with-header="false">
      <div style="text-align: center;width:400px;padding: 10px;"> <strong>发布记录</strong></div>

      <div style="height: 350px;margin: 20px 0px 0px 20px;">
        <el-steps direction="vertical" :active="0">
          <el-step v-for="item in pushRecords" :key="item.id" :title="item.ip + '  ' + item.typeDisplay"
            :description="item.createDate + '  发布人：' + item.userName"></el-step>
        </el-steps>
      </div>

    </el-drawer>
    <el-drawer size="250px" title="我是标题" :visible.sync="appFileDrawer" :with-header="false">
      <div style="text-align: center;width:250px; padding: 10px;"> <strong>品牌定制 APK 包</strong></div>
      <div style="width:100%;display: table-row">
        <el-tag :type="index % 2 != 0 ? 'warning' : ''" style="margin: 0px 0px 10px 10px;font-weight: 900;"
          :key="item.name" v-for="(item, index) in appFileList">{{ item.name }}</el-tag>
      </div>


    </el-drawer>

    <el-dialog :fullscreen="true" :title="dialogTitle" :visible.sync="dialogVisible" width="50%" center
      :before-close="clickModelHandler">
      <div class="logDiv" ref="logDiv">{{ javaPushLog }}</div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="closeDialog">关 闭</el-button>
      </span>
    </el-dialog>


    <el-dialog
      title="发布" :visible.sync="pushDialogVisible" width="30%">
     <span>
        地址：<el-radio-group v-for="item in otherAccount" :key="item.id" v-model="otherIp" @change="otherHandleAccountClick"
          size="mini" style="margin-left:5px" :fill='fillColor'>

          <el-radio-button :hidden="hiddenPath" :label="item.group.substring(0, 1)" disabled
            :class="{ 'ip_rb_first': item.group != 'mhp' }"
            :style="{ 'background-color': selectedColor }"></el-radio-button>
          <el-radio-button :hidden="hiddenPath" :label="item.ip"></el-radio-button>
        </el-radio-group>
      </span>

      <span slot="footer" class="dialog-footer">
        <el-button @click="pushDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="otherPush">确 定</el-button>
      </span>
  </el-dialog>

  </div>
</template>


<script>

import requestApi from "@/api/powerMenu.js";
import commonApi from "@/api/common.js";
import { baseURL } from "@/util/request.js";
import { webSocketUrl } from "@/util/request.js";
import pushApi from "@/api/push.js";
import serverApi from "@/api/server.js";
import { Loading } from 'element-ui';
import systemApi from "@/api/system.js";
import { quillEditor } from 'vue-quill-editor'
import requestUserApi from "@/api/user.js";
import _ from 'lodash';

let websocket = null;
let loading;

let logTimer;



export default {

  mounted() {
    this.webSocketLink();
    this.getMenuButtions();
  },
  data() {
    return {
      userId: localStorage.getItem("userId"),
      phone: localStorage.getItem("phone"),
      showFilelList: true,
      appFileList: [],
      limit: 10000,
      powerType: "",
      buttionArr: [],
      powerList: [],
      env: "",
      accept: ".jar",
      fileList: [],
      port: "",
      tableData: null,
      total: 10,
      pageIndex: 1,
      pageSize: 10,
      pageSizeArr: [10, 20, 50, 100],
      currentPage: 1,
      serverAcounts: {},
      acounts: {},
      ip: "",
      envCode: "",
      drawer: false,
      pushRecords: [],
      dialogVisible: false,
      javaPushLog: "",
      dialogTitle: "日志",
      baoTaPath: "#",
      removeFileRadio: false,
      forceUpdateRadio: false,
      canUpdateRadio: false,
      content: "",
      editorOption: {
        modules: {
          toolbar: [
            ["加粗", "italic", "underline", "strike"], // 加粗 斜体 下划线 删除线
            ["blockquote", "code-block"], // 引用  代码块
            [{ header: 1 }, { header: 2 }], // 1、2 级标题
            [{ list: "ordered" }, { list: "bullet" }], // 有序、无序列表
            [{ script: "sub" }, { script: "super" }], // 上标/下标
            [{ indent: "-1" }, { indent: "+1" }], // 缩进
            // [{'direction': 'rtl'}],                         // 文本方向
            [{ size: ["small", false, "large", "huge"] }], // 字体大小
            [{ header: [1, 2, 3, 4, 5, 6, false] }], // 标题
            [{ color: [] }, { background: [] }], // 字体颜色、字体背景颜色
            [{ font: [] }], // 字体种类
            [{ align: [] }], // 对齐方式
            ["clean"], // 清除文本格式
            ["link", "image", "video"] // 链接、图片、视频
          ], //工具菜单栏配置
        },
        placeholder: '请在这里添加产品描述', //提示
        readyOnly: false, //是否只读
        theme: 'snow', //主题 snow/bubble
        syntax: true, //语法检测
        pageSelectList: []
      },
      appFileDrawer: false,
      pushInfoSearch: "",
      fillColor: "#67C23A",
      selectedColor: "",
      page_url_show: false,
      pageUrlTaPath: "#",
      webPath: "",
      hiddenPath: false,
      accountsGroup: [],
      activeName: "MHP",
      pushDialogVisible: false,
      otherAccount:[],
      otherIp:"",
      otherEnvCode:"",
      otherPushRow:{},
      localUrl:"",
      serverUrl:"/www/file/android"
    };
  },
  created() {
  
    this.detail();
    this.getAccounts();

  },
  methods: {
    //websocket
    webSocketLink() {
      //userId = 登陆用户id

      let clientId = "push-" + this.userId;
      //判断当前浏览器是否支持WebSocket, 主要此处要更换为自己的地址
      if ('WebSocket' in window) {
        websocket = new WebSocket(webSocketUrl + clientId);
      } else {
        alert('Not support websocket')
      }

      //连接发生错误的回调方法
      websocket.onerror = function (event) {
        console.log("link error：" + event);
      };

      //连接成功建立的回调方法
      websocket.onopen = function (event) {
        console.log("webSocket " + clientId + " link ok.");
      }

      //接收到消息的回调方法
      websocket.onmessage = function (event) {
        //todo 准备的工作

        let message = JSON.parse(event.data);
        console.log(message)
        if (message.type == 1) {
          loading = Loading.service({ fullscreen: true, text: message.value + "%", background: "rgba(0, 0, 0, 0.7)" });
          loading.text = message.value + "%";
          if (message.isFinish) {

            loading.close();

          }
        }
      }
      //连接关闭的回调方法
      websocket.onclose = function (event) {
        console.log("webSocket link close.")
      }
      //监听窗口关闭事件，当窗口关闭时，主动去关闭websocket连接，防止连接还没断开就关闭窗口，server端会抛异常。
      window.onbeforeunload = function () {
        websocket.close();
      }
    },
   
    detail() {
      let params = {};
      this.$get(systemApi.webDetail, params).then((res) => {
        if (res.success && res.statusCode == 200) {
          this.webPath = JSON.parse(res.data)

        }
      });
    },
    tabClick(tab) {
      this.getPowerList()
      this.bindIp();
     
    },
    getAccounts() {
      let params = {};
      this.$get(serverApi.getAccounts, params).then((res) => {
        if (res.success && res.statusCode == 200) {
          this.serverAcounts = res.data;

          const serverGroupedBy = {};
          for (const item of this.serverAcounts) {
            if (serverGroupedBy[item.group]) {
              serverGroupedBy[item.group].push(item);
            } else {
              serverGroupedBy[item.group] = [item];
            }
          }
          let getUserLinkServerParams = { userId: this.userId }
          //根据用户配置的权限，显示
          this.$get(requestUserApi.getUserLinkServer, getUserLinkServerParams).then(f=>{
            if (res.success && res.statusCode == 200) {
              let filteredA = {};
              f.data.forEach(key => {
                if (serverGroupedBy.hasOwnProperty(key)) {
                  filteredA[key] = serverGroupedBy[key];
                }
              });
              this.accountsGroup = filteredA;
              this.bindIp();
            }
          })
        
        }
      });
    },
    bindIp() {
    
      this.acounts = this.serverAcounts.filter(f => f.labelDisplay == this.env && f.group == this.activeName);
      if (this.acounts.length == 0) {
        return;
      }
    
      this.ip = this.acounts[0].ip;
      this.envCode = this.acounts[0].label;
     
      this.installPath();

    },
    installPath() {
      let data = this.acounts.filter(f => f.ip == this.ip);
      this.baoTaPath = data[0].baoTaPath;
      let env = "test";
      if (this.env == "测试") {
        env = "test";
      } else if (this.env == "演示") {
        env = "beta";
      } else if (this.env == "正式") {
        env = "prod";
      }
      let webData = this.webPath.filter(f => f.env == env && f.code == this.powerType)

      if (webData.length > 0 && webData[0].pageUrl) {

        this.pageUrlTaPath = webData[0].pageUrl;
      } else {
        this.pageUrlTaPath = "#"
      }
      this.getPage();
    }
    ,
    //获取权限关联按钮
    getMenuButtions() {

      //获取按钮权限
      const menuId = this.$route.query.meunId;
      this.$get("/user/getUserButton", { menuId: menuId }).then((res) => {
          if (res.success && res.statusCode == 200) {
              this.buttionArr = res.data
              console.log(this.buttionArr)
              if (this.buttionArr.length > 0) {
                  this.env = this.buttionArr[0].menuName;

                  this.getPowerList();
                  this.bindIp();
              }
          }
      });


    },
    handleClick(tab) {
      this.getPowerList();
      this.bindIp();
    },
    handleEnvClick(tab) {
      this.bindIp();
      this.getPage();
      this.clearFiles()

      if (this.env == "正式" && this.powerType .indexOf("Java") ==-1) {
        this.acounts = this.acounts.filter(f => f.label == "prod-1")
      }
    },
    handlePowerClick(tab) {
      this.port = tab;
     
      if (tab .indexOf('Java')!=-1 ) {
        this.showFilelList = true;
        this.accept = ".jar";
        this.$refs.uploadFile.$children[0].$refs.input.webkitdirectory = false;
        this.limit = 10000;
      }
      else if (tab.indexOf('特斯科-Android')!=-1) {
        this.showFilelList = false;
        this.limit = 1000;
        this.accept = ".apk";
        this.acounts = this.serverAcounts;
        
      }
      else if (tab == 'Android') {
      
        this.showFilelList = false;
        this.limit = 1000;
        this.accept = ".apk";
        this.$refs.uploadFile.$children[0].$refs.input.webkitdirectory = true;
        this.acounts = this.serverAcounts;
      }
      else {
       
        this.limit = 1;
        this.showFilelList = true;
        this.accept = ".zip";
        this.$refs.uploadFile.$children[0].$refs.input.webkitdirectory = false;
        this.acounts = this.serverAcounts;
      }

      this.clearFiles();
      this.bindIp();
      if (this.env == "正式" && this.powerType .indexOf("Java") ==-1) {
        
        this.acounts = this.acounts.filter(f => f.label .indexOf("prod-1") !=-1)
       
      }
      this.getPage();
      console.log(this.acounts)
    },
    handleAccountClick(tab) {

      let data = this.serverAcounts.filter(f => f.ip == this.ip);
      if (data.length > 0) {
        this.envCode = data[0].label
      }

      this.installPath();
      this.getPage();

    },
    otherHandleAccountClick(tab) {
      let data = this.otherAccount.filter(f => f.ip == this.otherIp);
      if (data.length > 0) {
        this.otherEnvCode = data[0].label
      }
    
    }
    ,
    clearFiles() {
      if(this.$refs.uploadFile){
        this.$refs.uploadFile.clearFiles();
      }
     
      this.fileList = [];
      this.appFileList = [];
    },
    getPowerList() {
     
      let from = { userId: this.userId }
      this.$get(requestApi.getPowerList, from).then((res) => {
        if (res.success && res.statusCode == 200) {
          this.powerList = res.data;
          this.powerList.sort((a, b) => {
              const aContainsJava = (a.name.includes('Java') || a.name.includes('java')) ? 1 : 0;
              const bContainsJava = (b.name.includes('Java') || b.name.includes('java')) ? 1 : 0;
              return bContainsJava - aContainsJava;
            });
          if (this.powerList.length > 0) {

            this.powerList = this.powerList.filter(f => f.key.includes(this.activeName))
           
            this.powerType = this.powerList[0].name
         
           
            this.port = this.powerType;

            if (this.powerType .indexOf('Java')!=-1) {
              this.$refs.uploadFile.$children[0].$refs.input.webkitdirectory = false;
              this.accept = ".jar";
            }
            else if (this.powerType.indexOf('特斯科-Android') !=-1) {
              this.showFilelList = false;
              this.accept = ".apk";
            }
            else if (this.powerType.indexOf('Android') !=-1) {
              this.showFilelList = false;
              this.accept = ".apk";
              this.$refs.uploadFile.$children[0].$refs.input.webkitdirectory = true;
            }
            else {
              this.$refs.uploadFile.$children[0].$refs.input.webkitdirectory = false;
              this.accept = ".zip";
            }
           
            this.installPath();
            this.getPage();
          }
        }
      });
    },
    async uploadFileToOss(file) {
      let from = new FormData()
      from.append('file', file.raw)
      from.append('env', this.env)
      from.append('powerType', this.powerType)
      console.log("updatte")
      if (await this.beforeAvatarUpload(file)) {
        await this.$post(commonApi.upload, from).then((res) => {
          if (res.success && res.statusCode == 200) {
            this.url = res.data;
            console.log(this.url);
            if (this.url) {
              this.addPushInfo(file.name)

              loading.close();
            }
          }
        });
      }
    },
    addPushInfo(fileName) {
      let from = {
        'env': this.env,
        'serverPort': this.powerType,
        'url': this.url,
        "ip": this.ip,
        "envCode": this.envCode,
        isDelFile: this.removeFileRadio,
        forceUpdate: this.forceUpdateRadio,
        canUpdate: this.canUpdateRadio,
        content: this.content,
        userId: this.userId
      };
      this.$post(pushApi.add, from).then((res) => {
        if (res.success && res.statusCode == 200) {
          this.$msg_ok(fileName + "，发布完成")
          this.$nextTick(f => {
            this.getPage();
            this.clearFiles();
          })
        }


      });
    },
    pushServerClick(){
      let form = new FormData()
      form.append('file', this.fileList[0].raw)
      form.append('env', this.env)
      form.append('serverPort', this.powerType)
      form.append('ip', this.ip)
      form.append('envCode', this.envCode)
      form.append('userId', this.userId)
      // 构建 FormData 对象
    
     
      let api = baseURL+"/"+pushApi.pushServer;
      console.log(api)
      this.$confirm('是否发布到服务器本地？', '友情提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$post(api, form).then((res) => {
        if (res.success && res.statusCode == 200) {
          this.$nextTick(f => {
            this.getPage();
            this.clearFiles();
          })        
        }
      });
        
      }).catch(() => {
      });

     
    }
    ,
    getPage() {
     
      this.page = { pageIndex: this.pageIndex, pageSize: this.pageSize, env: this.env, serverPort: this.powerType, keyWords: this.pushInfoSearch, ip: this.ip };
      this.$post(pushApi.page, this.page).then((res) => {
        if (res.success && res.statusCode == 200) {
          this.tableData = res.data.records;
          this.total = res.data.total;

        }
      });
    },
    handleSelectionChange(selection, row) {
      this.pageSelectList = selection;
    },
    //分页查询
    pushSearch() {
      this.getPage();
    },
    beforeAvatarUpload(file) {
      if (this.port .indexOf('Java') !=-1) {
        let testmsg = file.name.substring(file.name.lastIndexOf(".") + 1);
        const extension = testmsg === "jar";
        if (!extension) {
          this.$message({
            message: "上传文件只能是.jar格式!",
            type: "warning",
          });
        }
        return extension;
      } else if (this.port .indexOf('Android') !=-1) {
        let testmsg = file.name.substring(file.name.lastIndexOf(".") + 1);
        const extension = testmsg === "apk";
        if (!extension) {
          this.$message({
            message: "上传文件只能是.apk格式!",
            type: "warning",
          });
        }
        return extension;
      }
      else {
        let testmsg = file.name.substring(file.name.lastIndexOf(".") + 1);

        let extension = (testmsg === "zip");
        if (!extension) {
          this.$message({
            message: "上传文件只能是zip格式，请确认",
            type: "warning",
          });
        }
        return extension;
      }

    },
    delFile(param) {
      let from = { path: this.url }
      this.$get(commonApi.del, from);
    },
    changeFile(file, files) {
      this.fileList = files;
   
      if (this.powerType.indexOf("Android")!=-1) {
        this.fileList = this.fileList.filter(f => f.name.lastIndexOf(".apk") != -1);
        this.appFileList = this.fileList;
        console.log(this.fileList)
      }
      if (this.powerType.indexOf("Java")!=-1 ) {
        this.fileList = []
        this.appFileList = []
        this.fileList.push(file)
        this.appFileList.push(file)
        this.showFilelList = true;
      }

    },
    submit() {
      this.$confirm('是否上传发布？', '友情提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
      
        if (this.powerType == 'Android') {
          this.appFileList.forEach(element => {

            this.uploadFileToOss(element)
          });

        } else {
          this.uploadFileToOss(this.fileList[0])
        }
      }).catch(() => {
      });
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.getPage();
    },
    handleCurrentChange(val) {
      this.pageIndex = val;
      this.getPage();
    },
    jarLog(row) {
      // this.javaPushLog ="";
      let params = { pushId: row.pushId, envCode: this.envCode, ip: this.ip };
      this.$get(pushApi.getJarLog, params).then((res) => {
        if (res.success && res.statusCode == 200) {
          this.javaPushLog = res.data;
          this.scrollToBottom();
        }
      });
    },
    closeDialog() {
      this.clickModelHandler();
    },
    clickModelHandler() {
      this.dialogVisible = false;
      clearInterval(logTimer);
      this.javaPushLog = "";
    },
    scrollToBottom() {
      this.$nextTick(() => {
        let container = document.getElementsByClassName("logDiv");
        container.scrollTop = container.scrollHeight;
      });
    },
    view(row) {
      this.dialogVisible = true;
      this.dialogTitle = this.env + "（" + this.ip + "）" + row.fileName.replace("-1.0.0.jar", ".log");
      this.jarLog(row);
      if (this.powerType .indexOf("Java")!=-1 ) {
        logTimer = setInterval(() => {
          console.log("定时查询日志")
          this.jarLog(row);
        }, 2000);
      }
    },
    appFielsView() {
      this.appFileDrawer = true;
    },
    otherPushDialog(row){
     
      if(this.acounts.length>1){
        this.pushDialogVisible=true
        this.otherAccount = _.cloneDeep(this.acounts);
        this.otherIp= this.ip;
        this.otherPushRow=row
        this.otherEnvCode= this.envCode
  
      }
    },
    otherPush(){
      let row = this.otherPushRow
      let mes = this.env + "-" + this.otherEnvCode;
      if (this.env === "正式") {
        mes = mes + '（' + this.otherIp + '）';
      }
      this.$confirm(mes + ' ，是否发布？', '友情提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {

        let params = { pushId: row.pushId, envCode: this.otherEnvCode, ip: this.otherIp, isDelFile: this.removeFileRadio };
        this.pushDialogVisible=false
        this.$post(pushApi.rePush, params).then((res) => {
          if (res.success && res.statusCode == 200) {
            this.$nextTick(f => {
              this.ip = this.otherIp
              this.getPage();
             
            })
          }
        });
      }).catch(() => {

      });
    },
    push(row) {

      let mes = this.env + "-" + this.envCode;
      if (this.env === "正式") {
        mes = mes + '（' + this.ip + '）';
      }
      this.$confirm(mes + ' ，是否发布？', '友情提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {

        let params = { pushId: row.pushId, envCode: this.envCode, ip: this.ip, isDelFile: this.removeFileRadio };
     
        this.$post(pushApi.rePush, params).then((res) => {
          if (res.success && res.statusCode == 200) {
            this.$nextTick(f => {
              this.getPage();
            })
          }
        });
      }).catch(() => {

      });
    },
    deleteInfo(row) {
      let mes = this.env + "-" + this.port;
      let packMes = "";
      if (this.port == "Android" || this.port == "android") {
        packMes = "-安卓包，删除后将无法恢复"
      }
      this.$confirm(mes + packMes + '，是否删除？', '友情提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let params = { pushId: row.pushId };
        this.$del(pushApi.deleteInfo, params).then((res) => {
          if (res.success && res.statusCode == 200) {
            this.$nextTick(() => {
              this.getPage();
            })
          }
        });
      }).catch(() => {

      });
    },
    deleteBatchsInfo() {
      let packMes = "";
      if (this.port == "Android" || this.port == "android") {
        packMes = "-安卓包，删除后将无法恢复"
      }
      let mes = this.env + "-" + this.port;
      this.$confirm(mes + packMes + '，是否批量删除？', '友情提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let pushIds = this.pageSelectList.map(v => v.pushId);
        this.$post(pushApi.deleteInfos, pushIds).then((res) => {
          if (res.success && res.statusCode == 200) {
            this.$nextTick(() => {
              this.pageIndex = 1;
              this.getPage();
            })
          }
        });

      }).catch(() => {

      });

    },
    tableDbClickHandeler(row) {
      this.drawer = true;
      this.pushRecords = row.childList;
    }
  },
  components: {
    quillEditor
  },
  props: {},
  watch: {
    'processData': 'scrollToBottom'
  },
  computed: {
    editor() {
      return this.$refs.myTextEditor.quillEditor;
    }

  },


};
</script>
<style scoped>
.el-aside {
  background-color: white;
  color: #333;
}

.el-main {
  background-color: white;

}



.editor {
  width: 84%;
}

.ql-snow .ql-tooltip[data-mode=link]::before {
  content: "请输入链接地址:";
}

.ql-snow .ql-tooltip.ql-editing a.ql-action::after {
  border-right: 0px;
  content: '保存';
  padding-right: 0px;
}

.ql-snow .ql-tooltip[data-mode=video]::before {
  content: "请输入视频地址:";
}

.ql-snow .ql-picker.ql-size .ql-picker-label::before,
.ql-snow .ql-picker.ql-size .ql-picker-item::before {
  content: '14px';
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value=small]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value=small]::before {
  content: '10px';
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value=large]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value=large]::before {
  content: '18px';
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value=huge]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value=huge]::before {
  content: '32px';
}

.ql-snow .ql-picker.ql-header .ql-picker-label::before,
.ql-snow .ql-picker.ql-header .ql-picker-item::before {
  content: '文本';
}

.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="1"]::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="1"]::before {
  content: '标题1';
}

.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="2"]::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="2"]::before {
  content: '标题2';
}

.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="3"]::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="3"]::before {
  content: '标题3';
}

.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="4"]::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="4"]::before {
  content: '标题4';
}

.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="5"]::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="5"]::before {
  content: '标题5';
}

.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="6"]::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="6"]::before {
  content: '标题6';
}

.ql-snow .ql-picker.ql-font .ql-picker-label::before,
.ql-snow .ql-picker.ql-font .ql-picker-item::before {
  content: '标准字体';
}

.ql-snow .ql-picker.ql-font .ql-picker-label[data-value=serif]::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value=serif]::before {
  content: '衬线字体';
}

.ql-snow .ql-picker.ql-font .ql-picker-label[data-value=monospace]::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value=monospace]::before {
  content: '等宽字体';
}
</style>
<style lang="scss" scoped>
.container {
  width: 100%;
  height: 90vh;
}

.main-radio {
  margin: 50px 0px 0px 5%;
}

.main-center .main-page {
  float: right;
}

.main-table {
  width: 100%;
  margin-top: 30px;
}

.main-table-top {
  width: 100%;
  background-color: white;
  box-shadow: 0 5px 12px 5px rgb(0 0 0 / 10%);
  height: 70px;
  display: flex;
  justify-items: center;
}

.main-page {
  float: right;
}

.demo-drawer-footer {
  position: fixed;
  top: 90%;
  margin-left: 20px;

}

.logDiv {
  white-space: pre-wrap;
  background-color: white;
  color: black;
  padding: 10px;
}

::v-deep .ip_rb_first .el-radio-button__inner {
  background-color: v-bind(selectedColor);
  color: darkslategray;
  height: 28px;
  border-radius: 20px 0px 0px 20px;
  width: 25px;
  display: inline-block;
}

.radio_style {
  margin: 0px 5px 5px 0px
}

.transition-box {
  margin-bottom: 10px;
  width: 80px;
  height: 20px;
  border-radius: 4px;
  background-color: #409EFF;
  text-align: center;
  color: #fff;
  padding: 5px 5px;
  box-sizing: border-box;

}
</style>